import {
	mapState
} from 'vuex'

export default {
	data() {
		return {

		}
	},
	computed: {
		...mapState(['userInfo', 'examsPaperStatus', 'examsPaperAnswerCache'])
	},
	filters: {
		// 显示隐藏
		visibilityFilter(v) {
			if (v) {
				return {
					visibility: 'visible'
				}
			} else {
				return {
					visibility: 'hidden'
				}
			}
		},
		// 判断题选项字母
		estimateLetterFilter(v) {
			if (v == 0) {
				return 'A'
			} else if (v == 1) {
				return 'B'
			} else if (v == 2) {
				return 'C'
			} else if (v == 3) {
				return 'D'
			} else if (v == 4) {
				return 'E'
			} else if (v == 5) {
				return 'F'
			} else if (v == 6) {
				return 'G'
			} else if (v == 7) {
				return 'H'
			} else if (v == 8) {
				return 'I'
			} else if (v == 9) {
				return 'J'
			} else if (v == 10) {
				return 'K'
			} else if (v == 11) {
				return 'L'
			} else if (v == 12) {
				return 'M'
			} else if (v == 13) {
				return 'N'
			} else if (v == 14) {
				return 'O'
			} else if (v == 15) {
				return 'P'
			} else if (v == 16) {
				return 'Q'
			} else if (v == 17) {
				return 'R'
			} else if (v == 18) {
				return 'S'
			} else if (v == 19) {
				return 'T'
			} else if (v == 20) {
				return 'U'
			} else if (v == 21) {
				return 'V'
			} else if (v == 22) {
				return 'W'
			} else if (v == 23) {
				return 'X'
			} else if (v == 24) {
				return 'Y'
			} else if (v == 25) {
				return 'Z'
			} else {
				return ''
			}
		},
	},
	methods: {
		// 吐丝
		toast(data, type) {
			if (typeof data === "string") {
				this.$message({
					message: data,
					type: type || 'warning',
				});
			} else {
				this.$message(data);
			}
		},
		// 复制
		copy(text) {
			try {
				const t = document.createElement('textarea')
				t.nodeValue = text
				t.value = text
				document.body.appendChild(t)
				t.select()
				document.execCommand('copy');
				document.body.removeChild(t)

				this.toast({
					message: '复制成功',
					type: 'success'
				})

				return true
			} catch (e) {
				console.log(e)
				return false
			}
		},
		navigateBack(delta = -1) {
			this.$router.go(delta)
		},
		openUrl(obj) {
			this.$router.push(obj)
		},
		replaceUrl(obj) {
			this.$router.replace(obj)
		},
		//控制富文本图片大小
		formatRichText(html) {
			return html
			// console.log(html, 'llll');
			// let newContent = html.replace(/<img[^>]*>/gi, function(match, capture) {
			// 	match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
			// 	match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
			// 	match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
			// 	return match;
			// });
			// newContent = newContent.replace(/style="[^"]+"/gi, function(match, capture) {
			// 	match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi,
			// 		'max-width:100%;');
			// 	return match;
			// });
			// newContent = newContent.replace(/<br[^>]*\/>/gi, '');
			// newContent = newContent.replace(/\<img/gi,
			// 	'<img style="max-width:100%;height:auto;display:inline-block;margin:10rpx auto;"');
			// return newContent;



			// var newContent = html.replace(/<img[^>]*>/gi, function(match, capture) {
			// 	let processed = null;

			// 	if (!match.match(/style=\"(.*)\"/gi)) {
			// 		processed = match.replace(/\<img/g, '<img style="width:100%;height:auto;display:block"');
			// 	} else {
			// 		processed = match.replace(/style=\"(.*)\"/gi,
			// 			'style="width: 100%;height:auto;display:block;margin: 0 auto;"');
			// 	}

			// 	return processed;
			// });
			// return newContent;
		},
	}
}