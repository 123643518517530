const config = {
  host: '', //接口域名
  imgRoot: '', //图片前缀
};
if (process.env.NODE_ENV === 'development') {
  //开发环境
  config.host = 'https://saas-uat4.ecdigit.cn/ecdigit/api/onlineexams';
} else if (process.env.NODE_ENV === 'production') {
  //生产环境
  config.host = '/ecdigit/api/onlineexams';
}
export default config;

// config.host = '/dev-api'
// config.host = 'https://saas-uat4.ecdigit.cn/ecdigit/api/onlineexams'
// config.host = location.protocol + '//' + location.hostname
